@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  scroll-behavior: smooth;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.comment::after{
  content: "";
  background-image: url("image/vector 1.png");
  width: 100%;
  height: 90%;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 35px;
  top: -29px;
}

.comment{
  width: 718px;
  height: 100px;
  position: relative;
  right: 70px;
  top: 62px;
}
.comment1::after{
  content: "";
  background-image: url("image/Vector 2.svg");
  width: 100%;
  height: 100%;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: -60px;
  left: 132px;
}
.comment1{
  width: 80%;   height: 150px;   position: relative;   right: 70px;   top: 62px;
}

.comment2::after{
  content: "";
  background-image: url("image/vector 1.png");
  width: 100%;
  height: 90%;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: -29px;
}

.comment2{
  width: 718px;
  height: 100px;
  position: relative;

  top: 77px;
}

.comment4::after{
  content: "";
  background-image: url("image/Vector 4.svg");
  width: 100%;
  height: 100%;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: -60px;
  left: 260px;
}
.comment4{
  width: 80%;   height: 150px;   position: relative;   right: 70px;   top: 62px;
}

.comment5::after{
  content: "";
  background-image: url("image/vector 1.png");
  width: 100%;
  height: 90%;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: -29px;
}

.comment5{
  width: 718px;
  height: 100px;
  position: relative;
  
  top: 72px;
}


 @media(max-width : 1320px){
  .comment1,.comment2,.comment4,.comment5,.comment3,.comment{
    display: none;
  }
 }
 #grid:hover{
  transform: scale(1.09);
  
  transition: 1s;
 }
#next::before{
  font-size: 40px;
  color: black;
}

#next1::before{
  font-size: 40px;
  color: black;
  margin: -18px;
}
@media(max-width:639px){
  #next::before{
    font-size: 20px;
    color: black;
    margin: auto;
  }
  
  #next1::before{
    font-size: 20px;
    color: black;
    margin: auto
  }
}
@media(min-width:1158px){
  #menu{
    display: none;
  }
}

.slick-dots li{
  font-size: 15px;
}
/*#service1,#service2,#service3,#service4,#service5,#service6,#service7,#service8,#service9,#service10:target{
    padding-top: 7em; 
    margin-top: -7em; 
}*/